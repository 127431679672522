import { useCallback, useEffect, useState } from "react";
import { getLoginToken } from "../App";

interface BaseProps {
    path: string;
    start: boolean;
}

type GetProps = {
    method: "GET" | "HEAD";
    postData?: never;
};

type PostProps = {
    method: "POST" | "DELETE" | "PUT";
    postData: object | undefined;
};

type ConditionalProps = GetProps | PostProps;

type Props = BaseProps & ConditionalProps;

function getUrl(relative: string) {
    // eslint-disable-next-line
    const urlExpression =
        "https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)";
    const regex = new RegExp(urlExpression);

    if (relative.match(regex)) {
        return relative;
    }

    var mainURL = process.env.REACT_APP_API_URL ?? "http://localhost:8080";
    if (mainURL === undefined) return "";
    if (mainURL.charAt(mainURL.length - 1) !== "/") mainURL += "/";

    if (relative.length > 0 && relative.charAt(0) === "/")
        relative = relative.substring(1, relative.length);

    return mainURL + relative;
}

export function useFetch<T>({ path, method, postData, start }: Props): {
    result: T | undefined;
    loading: boolean;
    refresh: (postData?: object | undefined) => void;
    error: boolean;
    statusCode: number;
    errorData: { status: number; error: string } | undefined;
} {
    const [result, setResult] = useState<T | undefined>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<boolean>(false);
    const [statusCode, setCode] = useState(-1);
    const [errorData, setErrorData] = useState<
        | {
              status: number;
              error: string;
          }
        | undefined
    >();

    const fetchData = useCallback(async (data: object | undefined) => {
        if (loading) {
            return;
        }

        setLoading(true);

        const staticURL = getUrl(path);

        const authToken = getLoginToken();
        let headers: Headers = new Headers();
        headers.set("Content-Type", "application/json");
        if (authToken) headers.set("Authorization", authToken);
        const response = await fetch(staticURL, {
            headers: headers,
            mode: "cors",
            body: JSON.stringify(data === undefined ? undefined : data),
            method: method,
        });

        setCode(response.status);

        try {
            const text = await response.text();
            const data = JSON.parse(text);
            setResult(data);
            if (!response.ok) {
                setError(true);
                setErrorData(data);
            }
        } catch (err) {
            setResult(undefined);
            setError(true);
        }

        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (start) {
            fetchData(postData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData, start]);

    const refresh = (postData?: object | undefined) => {
        fetchData(postData);
    };

    return { result, loading, refresh, error, statusCode, errorData };
}

export function useGet<T = object>({
    path,
    start,
}: BaseProps): {
    result: T | undefined;
    loading: boolean;
    refresh: () => void;
    error: boolean;
    statusCode: number;
    errorData: { status: number; error: string } | undefined;
} {
    return useFetch<T>({ path, method: "GET", start });
}

export function usePost<T = object>({
    path,
    start,
    postData,
}: BaseProps & PostProps): {
    result: T | undefined;
    loading: boolean;
    refresh: (postData?: object | undefined) => void;
    error: boolean;
    statusCode: number;
    errorData: { status: number; error: string } | undefined;
} {
    const fetchResult = useFetch<T>({ path, method: "POST", start, postData });
    return fetchResult;
}
