import React, {
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SidebarLinkGroup from "./SidebarLinkGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRightToBracket,
    faChevronDown,
    faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../App";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

interface SidebarProps {
    sidebarOpen: boolean;
    setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
    const location = useLocation();
    const { pathname } = location;

    const trigger = useRef<any>(null);
    const sidebar = useRef<any>(null);

    const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null
            ? false
            : storedSidebarExpanded === "true"
    );

    const { userData } = useContext(UserContext);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: MouseEvent) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: KeyboardEvent) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    });

    useEffect(() => {
        localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
        if (sidebarExpanded) {
            document.querySelector("body")?.classList.add("sidebar-expanded");
        } else {
            document
                .querySelector("body")
                ?.classList.remove("sidebar-expanded");
        }
    }, [sidebarExpanded]);

    return (
        <aside
            ref={sidebar}
            className={`absolute left-0 top-0 z-10 flex h-screen w-72.5 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
                sidebarOpen ? "translate-x-0" : "-translate-x-full"
            }`}
        >
            {/* <!-- SIDEBAR HEADER --> */}
            <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
                <NavLink to="/">
                    <img src={"/logo.png"} alt="Logo" />
                </NavLink>

                <button
                    ref={trigger}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    aria-controls="sidebar"
                    aria-expanded={sidebarOpen}
                    className="block lg:hidden"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            </div>
            {/* <!-- SIDEBAR HEADER --> */}

            <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
                {/* <!-- Sidebar Menu --> */}
                <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
                    {/* <!-- Menu Group --> */}
                    <div>
                        <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
                            MENU
                        </h3>

                        <ul className="mb-6 flex flex-col gap-1.5">
                            {/* <!-- Menu Item Dashboard --> */}
                            <SidebarLinkGroup
                                activeCondition={
                                    pathname === "/" ||
                                    pathname.includes("dashboard") ||
                                    pathname.includes("user/userlist")
                                }
                            >
                                {(handleClick, open) => {
                                    return (
                                        <Fragment>
                                            <NavLink
                                                to="#"
                                                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                                    (pathname === "/" ||
                                                        pathname.includes(
                                                            "dashboard"
                                                        ) ||
                                                        pathname.includes(
                                                            "user/userlist"
                                                        )) &&
                                                    "bg-graydark dark:bg-meta-4"
                                                }`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    sidebarExpanded
                                                        ? handleClick()
                                                        : setSidebarExpanded(
                                                              true
                                                          );
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faHouse}
                                                />
                                                Hlavní menu
                                                <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                    className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                                                        open && "rotate-180"
                                                    }`}
                                                />
                                            </NavLink>
                                            {/* <!-- Dropdown Menu Start --> */}
                                            <div
                                                className={`translate transform overflow-hidden ${
                                                    !open && "hidden"
                                                }`}
                                            >
                                                <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                                                    <li>
                                                        <NavLink
                                                            to="/"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
                                                                (isActive &&
                                                                    "!text-white")
                                                            }
                                                        >
                                                            Hlavní stránka
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            to="/dashboard/adminteam/"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
                                                                (isActive &&
                                                                    "!text-white")
                                                            }
                                                        >
                                                            Seznam členů teamu
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            to="/user/userlist/"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
                                                                (isActive &&
                                                                    "!text-white")
                                                            }
                                                        >
                                                            Seznam uživatelů
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <!-- Dropdown Menu End --> */}
                                        </Fragment>
                                    );
                                }}
                            </SidebarLinkGroup>
                            {/* <!-- Menu Item Dashboard --> */}

                            {/* <!-- Menu Item Chart --> */}
                            {userData && (
                                <li>
                                    <NavLink
                                        to="/ic"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                            pathname.includes("/ic") &&
                                            "bg-graydark dark:bg-meta-4"
                                        }`}
                                    >
                                        <FontAwesomeIcon
                                            icon={solid("gamepad")}
                                        />
                                        IC Sekce
                                    </NavLink>
                                </li>
                            )}

                            {/* <!-- Menu Item Auth Pages --> */}
                            {!userData && (
                                <SidebarLinkGroup
                                    activeCondition={
                                        pathname === "/auth" ||
                                        pathname.includes("auth")
                                    }
                                >
                                    {(handleClick, open) => {
                                        return (
                                            <React.Fragment>
                                                <NavLink
                                                    to="#"
                                                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                                        (pathname === "/auth" ||
                                                            pathname.includes(
                                                                "auth"
                                                            )) &&
                                                        "bg-graydark dark:bg-meta-4"
                                                    }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        sidebarExpanded
                                                            ? handleClick()
                                                            : setSidebarExpanded(
                                                                  true
                                                              );
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faArrowRightToBracket
                                                        }
                                                    />
                                                    Přihlášení
                                                    <FontAwesomeIcon
                                                        icon={faChevronDown}
                                                        className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                                                            open && "rotate-180"
                                                        }`}
                                                    />
                                                </NavLink>
                                                {/* <!-- Dropdown Menu Start --> */}
                                                <div
                                                    className={`translate transform overflow-hidden ${
                                                        !open && "hidden"
                                                    }`}
                                                >
                                                    <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                                                        <li>
                                                            <NavLink
                                                                to="/auth/login"
                                                                className={({
                                                                    isActive,
                                                                }) =>
                                                                    "group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
                                                                    (isActive &&
                                                                        "!text-white")
                                                                }
                                                            >
                                                                Přihlásit se
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* <!-- Dropdown Menu End --> */}
                                            </React.Fragment>
                                        );
                                    }}
                                </SidebarLinkGroup>
                            )}
                            {/* <!-- Menu Item Auth Pages --> */}
                        </ul>
                    </div>
                </nav>
                {/* <!-- Sidebar Menu --> */}
            </div>
        </aside>
    );
};

export default Sidebar;
