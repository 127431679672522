import {
    RouterProvider,
    createBrowserRouter,
    useNavigate,
} from "react-router-dom";
import {
    Suspense,
    createContext,
    lazy,
    useContext,
    useEffect,
    useState,
} from "react";
import Layout from "./Pages/Layout";
import { AdminRanks } from "./Components/AdminLabel";
import Loader from "./Pages/Loader";
import Middleware from "./Services/Middleware";
import { Alert } from "./Components/Alert";
import { useGet } from "./Services/useFetch";

const Home = lazy(() => import("./Pages/Dashboard/Home"));
const Login = lazy(() => import("./Pages/Auth/Login"));
const Profile = lazy(() => import("./Pages/User/Profile"));
const UserList = lazy(() => import("./Pages/User/UserList"));
const AdminTeam = lazy(() => import("./Pages/Dashboard/AdminTeam"));
const UnknownPage = lazy(() => import("./Pages/UnknownPage"));
const ShowICSite = lazy(() => import("./Pages/IcSection"));
const Post = lazy(() => import("./Pages/Dashboard/Post"));

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <Suspense fallback={<Loader />}>
                <Home />
            </Suspense>
        ),
    },
    {
        path: "/dashboard/post/:postId",
        element: (
            <Suspense fallback={<Loader />}>
                <Post />
            </Suspense>
        ),
    },
    {
        path: "/dashboard/adminteam",
        element: (
            <Suspense fallback={<Loader />}>
                <AdminTeam />
            </Suspense>
        ),
    },
    {
        path: "/user/profile/",
        element: (
            <Middleware isLoggedIn={true}>
                <Suspense fallback={<Loader />}>
                    <Profile />
                </Suspense>
            </Middleware>
        ),
    },
    {
        path: "/user/profile/:userId",
        element: (
            <Middleware isLoggedIn={true}>
                <Suspense fallback={<Loader />}>
                    <Profile />
                </Suspense>
            </Middleware>
        ),
    },
    {
        path: "/user/userlist/",
        element: (
            <Suspense fallback={<Loader />}>
                <UserList />
            </Suspense>
        ),
    },
    {
        path: "/auth/login",
        element: (
            <Middleware isGuest={true}>
                <Suspense fallback={<Loader />}>
                    <Login />
                </Suspense>
            </Middleware>
        ),
    },
    {
        path: "/auth/logout",
        Component: () => {
            const navigate = useNavigate();
            const { setUserData } = useContext(UserContext);
            const { result: data } = useGet({
                path: "/v1/auth/logout",
                start: true,
            });
            useEffect(() => {
                setLoginToken();
                setUserData(null);
                return navigate("/");
            }, [data, navigate, setUserData]);
            return (
                <Layout>
                    <></>
                </Layout>
            );
        },
    },
    ///////////////////////////// IC ////////////////////////////////////
    {
        path: "/ic/*",
        element: (
            <Middleware isLoggedIn={true}>
                <Suspense fallback={<Loader />}>
                    <ShowICSite />
                </Suspense>
            </Middleware>
        ),
    },
    {
        path: "*",
        element: (
            <Suspense fallback={<Loader />}>
                <UnknownPage />
            </Suspense>
        ),
    },
]);

type userContextType = {
    userData: userDataType | null;
    setUserData: React.Dispatch<React.SetStateAction<userDataType | null>>;
};

const iUserContextState = {
    userData: null,
    setUserData: () => {},
};

export const UserContext = createContext<userContextType>(iUserContextState);

type userDataType = {
    userid: number;
    username: string;
    staffRank: AdminRanks;
    selectedCharacter?: characterData;
};

type characterData = {
    id: number;
    name: string;
    date_of_birth: string;
    bankmoney: number;
};

export default function App() {
    const [userData, setUserData] = useState<userDataType | null>(null);

    const { result, loading, statusCode, errorData } = useGet<userDataType>({
        path: "/v1/@me",
        start: getLoginToken() ? true : false,
    });

    useEffect(() => {
        if (result) return setUserData(result);
    }, [result]);

    if (loading) return <Loader />;

    if (statusCode !== 200 && statusCode !== -1) {
        console.log(errorData?.error)
        if (errorData?.error !== "Expired authorization") {
            return (
                <Alert
                    type="ERROR"
                    title="CHYBA V APLIKACI"
                    message={
                        "Nepodařilo se načíst webovou stránku! (" +
                        errorData?.error +
                        ")"
                    }
                />
            );
        } else {
            setLoginToken();
            setUserData(null);
        }
    }

    return (
        <UserContext.Provider value={{ userData, setUserData }}>
            <RouterProvider router={router} />
        </UserContext.Provider>
    );
}

export const getLoginToken = () => {
    const data = localStorage.getItem("auth_token");
    if (data) return data;
    else return undefined;
};

export const setLoginToken = (data: string = "") => {
    if (data === "") return localStorage.removeItem("auth_token");
    else return localStorage.setItem("auth_token", data);
};
