import { ReactElement } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import PageContainer from "../Components/PageContainer";
import Layout from "./Layout";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const NoAccess = ({children}: {children: ReactElement}) => {
    return (
        <Layout>
            <Breadcrumb pageName="Přístup zamítnut"></Breadcrumb>
            <PageContainer>
                <div className="mx-auto max-w-[410px] p-6">
                    <div className="text-center">
                        <h2 className="mb-3 text-2xl font-bold text-white">
                            Omlouváme se, ale k této stránce nemáte přístup.
                        </h2>
                        <p className="font-medium">
                            {children}
                        </p>
                        <Link
                            className="mt-7.5 inline-flex items-center gap-2 rounded-md bg-primary py-3 px-6 font-medium text-white hover:bg-opacity-90"
                            to="/"
                        >
                            <FontAwesomeIcon icon={solid("arrow-left-long")} />
                            <span>Zpět domů</span>
                        </Link>
                    </div>
                </div>
            </PageContainer>
        </Layout>
    );
};

export default NoAccess;
