export type AdminRanks = {
    admin: number;
    supporter: number;
    scripter: number;
    mapper: number;
    vct: number;
};

interface AdminLabelProp {
    value?: AdminRanks;
    renderMultiple?: boolean;
}

const AdminLabel = ({
    value = { admin: 0, mapper: 0, scripter: 0, supporter: 0, vct: 0 },
    renderMultiple = false,
}: AdminLabelProp) => {
    let canRender = ["admin", "supporter", "mapper", "vct", "scripter"];

    if (renderMultiple === false) {
        if (value.admin >= 1) {
            canRender = ["admin"];
        } else if (value.supporter >= 1) {
            canRender = ["supporter"];
        } else if (value.mapper >= 1) {
            canRender = ["mapper"];
        } else if (value.vct >= 1) {
            canRender = ["vct"];
        } else if (value.scripter >= 1) {
            canRender = ["scripter"];
        }
    }

    return (
        <>
            {canRender.includes("admin") && value.admin >= 1 && (
                <button className="inline-flex rounded-full bg-danger py-1 px-3 text-white hover:bg-opacity-90">
                    Administrátor
                </button>
            )}
            {canRender.includes("supporter") && value.supporter >= 1 && (
                <button className="inline-flex rounded-full bg-success py-1 px-3 text-white hover:bg-opacity-90">
                    Supporter
                </button>
            )}
            {canRender.includes("mapper") && value.mapper >= 1 && (
                <button className="inline-flex rounded-full bg-[#3BA2B8] py-1 px-3 text-white hover:bg-opacity-90">
                    Mapper
                </button>
            )}
            {canRender.includes("vct") && value.vct >= 1 && (
                <button className="inline-flex rounded-full bg-[#3BA2B8] py-1 px-3 text-white hover:bg-opacity-90">
                    VCT
                </button>
            )}
            {canRender.includes("scripter") && value.scripter >= 1 && (
                <button className="inline-flex rounded-full bg-[#3BA2B8] py-1 px-3 text-white hover:bg-opacity-90">
                    Scripter
                </button>
            )}
        </>
    );
};

export default AdminLabel;
