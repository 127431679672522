import { ReactElement, useContext } from "react";
import NoAccess from "../Pages/NoAccess";
import { UserContext } from "../App";

type MiddlewareProp = {
    children: ReactElement;
    isLoggedIn?: boolean;
    isGuest?: boolean;
    selectedCharacter?: boolean;
};
const Middleware = ({
    children,
    isLoggedIn = false,
    isGuest = false,
    selectedCharacter = false,
}: MiddlewareProp) => {
    const { userData } = useContext(UserContext);

    if (isLoggedIn) {
        if (!userData)
            return (
                <NoAccess>
                    <>Pro přístup k této stránce, musíte být přihlášen</>
                </NoAccess>
            );
    }
    if (isGuest) {
        if (userData)
            return (
                <NoAccess>
                    <>Pro přístup k této stránce, nesmíte být přihlášen</>
                </NoAccess>
            );
    }
    if (selectedCharacter) {
        if (!userData)
            return (
                <NoAccess>
                    <>Pro přístup k této stránce, musíte být přihlášen</>
                </NoAccess>
            );
        else if (!userData.selectedCharacter)
            return (
                <NoAccess>
                    <>Pro přístup k této stránce, musíte být přihlášen</>
                </NoAccess>
            );
    }

    return children;
};
export default Middleware;
