import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

const PageContainer = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => {
    const style = twMerge("overflow-hidden rounded-lg border shadow-default border-strokedark bg-boxdark", className)
    return (
        <div className={style}>
            {children}
        </div>
    );
};

export default PageContainer;
